import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`

export const Title = styled.h1`
  font-size: 24px;
  padding-bottom: 24px;
  margin: 0;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  max-width: 100%;
  max-height: 100%;
  
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 640px) {
    box-shadow: unset;
  }
`

export const Form = styled.div`
  margin: 50px 75px;
`